import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import APIS from "../../apis"
import { Button, Col, Form, Label, Row } from "reactstrap"
import getDay from "date-fns/getDay"

const AddAvisRattrapage = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var annee_id = userAuthEnseignant.user.annee_id
  //
  const [date, setDate] = useState(null)
  const [quart, setQuart] = useState([])
  const [selectQuart, setSelectQuart] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [jourId, setJourId] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [nbrHeureSeance, setNbrHeureSeance] = useState([
    { value: 2, label: "1h" },
    { value: 3, label: "1h:30" },
    { value: 4, label: "2h" },
    { value: 5, label: "2h:30" },
    { value: 6, label: "3h" },
  ])
  const [selectNbrHeureSeance, setSelectNbrHeureSeance] = useState("");
  const [groupTd, setGroupTd] = useState([]);
  const [selectGroupTd, setSelectGroupTd] = useState("");
  const [display, setDisplay] = useState("none");
  useEffect(async () => {
    const resQ = await APIS.get("quart/select").then(resQ => {
      setQuart(resQ.data.Quart)
    })
  }, [])

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0
  }

  const getQuart = e => {
    setSelectQuart(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectGroupTd("")
    setDisplay("none")
    setSelectNbrHeureSeance("")
  }
  const getNbHeure = e => {
    setSelectNbrHeureSeance(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectGroupTd("")
    setDisplay("none")
  }

  const getDay1 = async e => {
    setDate(e)
    setSelectQuart("")
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectGroupTd("")
    setDisplay("none")
    setSelectNbrHeureSeance("")
    // convert date début
    let date = e
    let month = "" + (date.getMonth() + 1)
    let day = "" + date.getDate()
    let year = date.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDate = [year, month, day].join("-")
    const res = await APIS.post("quart/get_date", {
      date: convertDate,
    }).then(res => {
      setJourId(res.data.Date)
    })
  }
  const getTypeMatiere = async e => {
    setSelectSemestre(e)
    setSelectQuart("")
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectGroupTd("")
    setSelectNbrHeureSeance("")
    setDate(null)
    setDisplay("none")
    const res = await APIS.post("emploi/get_type_matiere", {
      enseignant_id: enseignant_id,
      semestre_id: e.value,annee_id
    }).then(res => {
      setType(res.data.emploi)
    })
  }

  const getMatiere = async e => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectGroupTd("")
    setDisplay("none")
    const res = await APIS.post("emploi/get_matiere", {
      type: e.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }

  const getGroupe = async e => {
    setSelectMatiere(e)
    setSelectGroupe("")
    const resG = await APIS.post("emploi/get_group", {
      quart_id: selectQuart.value,
      jour_id: jourId,
      quan: 0,
      matiere_id: e.value,
      type: selectType.value,
      enseignant_id: enseignant_id,
      semestre_id: selectSemestre.value,
      annee_id,
      nb_heure_seance:selectNbrHeureSeance.value
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
    if(selectType.value == 1){
      setDisplay("")
    }else{
      setDisplay("none")
    }
  }
  const getGroupTD = async e => {
    setSelectGroupe(e)
    setSelectGroupTd("")
    const resG = await APIS.post("emploi/get_group_td_by_group", {
      group_id: e
    }).then(resG => {
      setGroupTd(resG.data.groups)
    })
  }

  const save = async () => {
    if (date == null) {
      toast.error("⛔ Date obligatoire !", {
        containerId: "A",
      })
    } else if (selectQuart == "") {
      toast.error("⛔ Quart obligatoire !", {
        containerId: "A",
      })
    } else if (selectType == "") {
      toast.error("⛔ Type obligatoire !", {
        containerId: "A",
      })
    } else if (selectMatiere == "") {
      toast.error("⛔ Matiere obligatoire !", {
        containerId: "A",
      })
    } else if (selectGroupe != "") {
      // convert date début
      let dateRatt = date
      let month = "" + (dateRatt.getMonth() + 1)
      let day = "" + dateRatt.getDate()
      let year = dateRatt.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      let convertDate = [year, month, day].join("-")
      if(selectType.value == 1 ){
        var group=selectGroupTd
        var etat=1
      }else{
        var group=selectGroupe
        var etat=0
      }
      const res = await APIS.post("avis/rattrapage/add", {
        date: convertDate,
        jour_id: jourId,
        quart_id: selectQuart.value,
        matiere_id: selectMatiere.value,
        group_id: group,
        type_id: selectType.value,
        enseignant_id: enseignant_id,
        semestre_id: selectSemestre.value,
        quan: 0,
        etat:etat,
        annee_id,
        // nb_heure_seance:selectNbrHeureSeance.value
      })
        .then(res => {
          if(res.data.errNum ==="500"){
            toast.error(res.data.msg, {
              containerId: "A",
            })
          }else{
            props.setSection(1)
          }
        })
        .catch(() => {
          toast.error("Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("⛔ Groupe obligatoire !", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Semestre")}
                </Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  value={selectSemestre}
                  onChange={e => getTypeMatiere(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </Label>
                <DatePicker
                  selected={date}
                  filterDate={isWeekday}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy"
                  onChange={e => getDay1(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Quart")}
                </Label>
                <Select
                  options={quart}
                  isSearchable={true}
                  value={selectQuart}
                  onChange={e => getQuart(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Nbr heure seance</Label>
                <Select
                    options={nbrHeureSeance}
                    isSearchable={true}
                    value={selectNbrHeureSeance}
                    onChange={e => getNbHeure(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                  options={type}
                  isSearchable={true}
                  value={selectType}
                  onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                  options={matiere}
                  isSearchable={true}
                  value={selectMatiere}
                  onChange={e => getGroupe(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                  options={groupe}
                  isSearchable={true}
                  value={selectGroupe}
                  isMulti={true}
                  onChange={e => getGroupTD(e)}
                />
              </div>
            </Col>
          </Row>
          <Row  style={{display:display}}>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe TD</Label>
                <Select
                    options={groupTd}
                    isSearchable={true}
                    value={selectGroupTd}
                    isMulti={true}
                    onChange={e => setSelectGroupTd(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddAvisRattrapage))
AddAvisRattrapage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
